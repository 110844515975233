import React, { FC, useContext } from "react";
import * as styles from "./TestimonialMain.module.scss";
import "pure-react-carousel/dist/react-carousel.es.css";
import { LanguageContext } from "../../../../context/languageContext";
import CircleBlur from "../../../Shared/CircleOutlineAndBlur/CircleBlur";
import CircleOutline from "../../../Shared/CircleOutlineAndBlur/CircleOutline";
import { ImageUrlCheck } from "../../../Shared/ImageUrlCheck/ImageUrlCheck";

type Props = {
  data: any;
  testimonialindex: number;
};

const HomeTestimonialMain: FC<Props> = ({ data, testimonialindex }) => {
  const { language } = useContext(LanguageContext);
  let src = ImageUrlCheck(
    data.edges[testimonialindex].node._rawPicture.asset.url
  );
  return (
    <div className={styles.ContentBlock}>
      <div className={styles.singleCaseTestimonial}>
        <div className={styles.singleCaseTestimonialInner}>
          <img
            src={src}
            alt={data.edges[testimonialindex].node.name}
            className={styles.caseTestimonialImage}
          />
          <div className={styles.testimonialContent}>
            <h4>{data.edges[testimonialindex].node.name}</h4>
            <p className={styles.testimonialName}>
              {data.edges[testimonialindex].node.position}
            </p>
            <p className={styles.testimonialBody}>
              {language === "EN"
                ? data.edges[testimonialindex].node.description[0]
                    ._rawChildren[0].text
                : data.edges[testimonialindex].node.descriptionDe[0]
                    ._rawChildren[0].text}
            </p>

            <CircleBlur
              initialScale={0.1}
              initialOpacity={0}
              animateScale={1.5}
              animateOpacity={0.15}
              transitionType={"easeIn"}
              transitionDelay={0.2}
              transitionDuration={1}
              circleStyleClass={styles.heroBackgroundInnerCircleLeft}
            />
          </div>
        </div>
      </div>
      {/* <div
          className={"testimonial-carousel carousel__container"}
          style={{ marginTop: "-10px" }}
        >
          <CarouselProvider
            naturalSlideWidth={500}
            naturalSlideHeight={300}
            totalSlides={data.edges.length + 2}
            currentSlide={0}
            visibleSlides={1}
            infinite={true}
          >
            <Slider className={styles.testimonialSliderContainer}>
              {data.edges.map(({ node }: any, index: number) => {
                UseImageUrl(node.picture, node._rawPicture);
                return (
                  <TestimonialSlide
                    key={index}
                    sliderContainerClassName={styles.sliderItem}
                    sliderIndex={index}
                    sliderImage={node.picture.asset.gatsbyImageData}
                    testimonialName={node.name}
                    testimonialPosition={node.position}
                    testimonialDescription={
                      node.description[0]._rawChildren[0].text
                    }
                    testimonialDescriptionDe={
                      node.descriptionDe[0]._rawChildren[0].text
                    }
                  />
                );
              })}
            </Slider>
            <TestimonialSliderButtons
              sliderButtonsClassName={styles.testimonialButtonsContainer}
            />
          </CarouselProvider>
        </div> */}
      <div className={styles.backgroundCircles}>
        <CircleOutline
          isBlurVisible={false}
          initialScale={0.3}
          animateScale={1}
          transitionType={"easeIn"}
          transitionDelay={0.2}
          transitionDuration={1}
        />

        <CircleOutline
          isBlurVisible={false}
          initialScale={0.3}
          animateScale={1}
          transitionType={"easeIn"}
          transitionDelay={0.2}
          transitionDuration={1}
          circleStyleClass={styles.heroBackgroundCircleLeft}
        />
      </div>
    </div>
  );
};

export default HomeTestimonialMain;
