// extracted by mini-css-extract-plugin
export var articleBody = "HomeServicesMobile-module--articleBody--b3b51";
export var backgroundCircles = "HomeServicesMobile-module--backgroundCircles--6cf39";
export var heroBackgroundCircleLeft = "HomeServicesMobile-module--heroBackgroundCircleLeft--9deed";
export var heroBackgroundInnerCircleLeft = "HomeServicesMobile-module--heroBackgroundInnerCircleLeft--0f17c";
export var heroWhiteBlurCirce = "HomeServicesMobile-module--heroWhiteBlurCirce--cc078";
export var nextArticle = "HomeServicesMobile-module--nextArticle--c3916";
export var nextArticleContainer = "HomeServicesMobile-module--nextArticleContainer--a6079";
export var nextArticleText = "HomeServicesMobile-module--nextArticleText--b56f6";
export var numberAndName = "HomeServicesMobile-module--numberAndName--dd635";
export var readMore = "HomeServicesMobile-module--readMore--01f6e";
export var serviceArticle = "HomeServicesMobile-module--serviceArticle--9ac97";
export var servicesMobileWrapper = "HomeServicesMobile-module--servicesMobileWrapper--4e486";
export var sliderMain = "HomeServicesMobile-module--sliderMain--1deae";