// extracted by mini-css-extract-plugin
export var btnMargin = "OurCustomersMain-module--btnMargin--f4f9a";
export var carousel = "OurCustomersMain-module--carousel--bc621";
export var carouselContainer = "OurCustomersMain-module--carouselContainer--3cead";
export var carouselMainContainer = "OurCustomersMain-module--carouselMainContainer--28b59";
export var caseStudiesDescription = "OurCustomersMain-module--caseStudiesDescription--266cf";
export var caseStudiesHeader = "OurCustomersMain-module--caseStudiesHeader--ffe0e";
export var customerHeading = "OurCustomersMain-module--customerHeading--d7fb9";
export var customerSubDiv = "OurCustomersMain-module--customerSubDiv--8914b";
export var customers = "OurCustomersMain-module--customers--5f706";
export var evenSlides = "OurCustomersMain-module--evenSlides--bffb0";
export var heroBackgroundCircleLeft = "OurCustomersMain-module--heroBackgroundCircleLeft--ea361";
export var heroBackgroundInnerCircleLeft = "OurCustomersMain-module--heroBackgroundInnerCircleLeft--3f9ed";
export var sliderBtns = "OurCustomersMain-module--sliderBtns--0cf81";
export var sliderComp = "OurCustomersMain-module--sliderComp--7b20c";
export var subDiv = "OurCustomersMain-module--subDiv--14c9d";