// extracted by mini-css-extract-plugin
export var ContentBlock = "TestimonialMain-module--ContentBlock--a808c";
export var ImageBox = "TestimonialMain-module--ImageBox--aef2f";
export var ImageBoxForth = "TestimonialMain-module--ImageBoxForth--0936f";
export var ImageBoxSecond = "TestimonialMain-module--ImageBoxSecond--1bbd7";
export var TextBox = "TestimonialMain-module--TextBox--ab69c";
export var TextBoxLast = "TestimonialMain-module--TextBoxLast--2e162";
export var application = "TestimonialMain-module--application--6b819";
export var applicationHeading = "TestimonialMain-module--applicationHeading--eb77b";
export var applicationMap = "TestimonialMain-module--applicationMap--7e365";
export var approach = "TestimonialMain-module--approach--e4996";
export var backgroundCircles = "TestimonialMain-module--backgroundCircles--e6741";
export var banner = "TestimonialMain-module--banner--c607a";
export var benefits = "TestimonialMain-module--benefits--43a32";
export var bottomButton = "TestimonialMain-module--bottomButton--5fc31";
export var careerBtnApplyNow = "TestimonialMain-module--careerBtnApplyNow--e4de6";
export var careers = "TestimonialMain-module--careers--4afad";
export var caseChallengeImage = "TestimonialMain-module--caseChallengeImage--b1deb";
export var caseTestimonialImage = "TestimonialMain-module--caseTestimonialImage--02c6b";
export var contact = "TestimonialMain-module--contact--e97f9";
export var contactButton = "TestimonialMain-module--contactButton--10912";
export var contactOutlineCircleBenfits = "TestimonialMain-module--contactOutlineCircleBenfits--f04c0";
export var coreValue = "TestimonialMain-module--coreValue--bb8e6";
export var coreValueColumbLeft = "TestimonialMain-module--coreValueColumbLeft--84bef";
export var coreValueColumbRight = "TestimonialMain-module--coreValueColumbRight--8e5a8";
export var coreValueItem = "TestimonialMain-module--coreValueItem--66d2a";
export var coreValueItemBox = "TestimonialMain-module--coreValueItemBox--268c6";
export var flowContainer = "TestimonialMain-module--flowContainer--24a7e";
export var flowItem = "TestimonialMain-module--flowItem--17752";
export var flowItemDay = "TestimonialMain-module--flowItemDay--10497";
export var flowItemDayMobile = "TestimonialMain-module--flowItemDayMobile--84d3c";
export var flowItemDayMobileContainer = "TestimonialMain-module--flowItemDayMobileContainer--aff44";
export var flowItemMobile = "TestimonialMain-module--flowItemMobile--ffff0";
export var flowLeftContainer = "TestimonialMain-module--flowLeftContainer--27df9";
export var flowRightContainer = "TestimonialMain-module--flowRightContainer--8bc08";
export var flowRightContainerMobile = "TestimonialMain-module--flowRightContainerMobile--60855";
export var flowStop = "TestimonialMain-module--flowStop--454be";
export var followUs = "TestimonialMain-module--followUs--25b0f";
export var gdnPromotePic = "TestimonialMain-module--gdnPromotePic--bdfdf";
export var heading = "TestimonialMain-module--heading--8ff2c";
export var heroBackgroundCircleLeft = "TestimonialMain-module--heroBackgroundCircleLeft--7964f";
export var heroBackgroundInnerCircleGreen = "TestimonialMain-module--heroBackgroundInnerCircleGreen--a375e";
export var heroBackgroundInnerCircleLeft = "TestimonialMain-module--heroBackgroundInnerCircleLeft--7dd57";
export var heroBackgroundInnerCircleLeftBenfits = "TestimonialMain-module--heroBackgroundInnerCircleLeftBenfits--e019c";
export var heroBannerMainContainer = "TestimonialMain-module--heroBannerMainContainer--74d99";
export var heroWhiteBlurCirce = "TestimonialMain-module--heroWhiteBlurCirce--ae703";
export var igImageGroup1 = "TestimonialMain-module--igImageGroup1--37656";
export var igImageGroup1Item = "TestimonialMain-module--igImageGroup1Item--9638d";
export var igImageGroup2 = "TestimonialMain-module--igImageGroup2--aa6a2";
export var igImageGroup2Btm = "TestimonialMain-module--igImageGroup2Btm--7745c";
export var igImageGroup2BtmItem = "TestimonialMain-module--igImageGroup2BtmItem--fe37f";
export var igImageGroup2Item = "TestimonialMain-module--igImageGroup2Item--43492";
export var inNumberColumbLeft = "TestimonialMain-module--inNumberColumbLeft--d4218";
export var inNumberColumbRight = "TestimonialMain-module--inNumberColumbRight--3bad0";
export var inNumbersImage = "TestimonialMain-module--inNumbersImage--51873";
export var inNumbersItem = "TestimonialMain-module--inNumbersItem--cde8d";
export var innumbers = "TestimonialMain-module--innumbers--c34fa";
export var insights = "TestimonialMain-module--insights--09a96";
export var instaContainer = "TestimonialMain-module--instaContainer--e3320";
export var instaLeftBox = "TestimonialMain-module--instaLeftBox--c1622";
export var instaRightBox = "TestimonialMain-module--instaRightBox--33b79";
export var introDescriptionButtonSection = "TestimonialMain-module--introDescriptionButtonSection--86b6c";
export var introImageSection = "TestimonialMain-module--introImageSection--21d90";
export var introSectionCircleItem = "TestimonialMain-module--introSectionCircleItem--37c0e";
export var introSectionContainer = "TestimonialMain-module--introSectionContainer--798db";
export var introSectionText = "TestimonialMain-module--introSectionText--6fe7e";
export var keyTopicsImage = "TestimonialMain-module--keyTopicsImage--b25f5";
export var knowUs = "TestimonialMain-module--knowUs--d6cbe";
export var knowUsImage = "TestimonialMain-module--knowUsImage--c63d8";
export var knowUsText = "TestimonialMain-module--knowUsText--87e00";
export var pillars = "TestimonialMain-module--pillars--1c6eb";
export var reportBtn = "TestimonialMain-module--reportBtn--9f407";
export var reportBtnGerman = "TestimonialMain-module--reportBtnGerman--0fade";
export var singleCaseTestimonial = "TestimonialMain-module--singleCaseTestimonial--cac7f";
export var singleCaseTestimonialInner = "TestimonialMain-module--singleCaseTestimonialInner--3f780";
export var sliderItem = "TestimonialMain-module--sliderItem--63050";
export var testimonialBody = "TestimonialMain-module--testimonialBody--54377";
export var testimonialButtonsContainer = "TestimonialMain-module--testimonialButtonsContainer--91ccd";
export var testimonialContent = "TestimonialMain-module--testimonialContent--ef2e2";
export var testimonialMainContainer = "TestimonialMain-module--testimonialMainContainer--d541c";
export var testimonialName = "TestimonialMain-module--testimonialName--b6aa9";
export var testimonialSliderContainer = "TestimonialMain-module--testimonialSliderContainer--16485";
export var torchWrapper = "TestimonialMain-module--torchWrapper--0bd32";
export var verticalLine = "TestimonialMain-module--verticalLine--56913";
export var verticalLineMobile = "TestimonialMain-module--verticalLineMobile--f070e";
export var yourBenefits = "TestimonialMain-module--yourBenefits--46223";
export var yourBenefitsIcon = "TestimonialMain-module--yourBenefitsIcon--5d299";
export var yourBenefitsInner = "TestimonialMain-module--yourBenefitsInner--3243c";
export var yourBenefitsItem = "TestimonialMain-module--yourBenefitsItem--f656a";