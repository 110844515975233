// extracted by mini-css-extract-plugin
export var aboutHeader = "AboutMain-module--aboutHeader--6adc8";
export var aboutMainCircleBlurBlue = "AboutMain-module--aboutMainCircleBlurBlue--54ea6";
export var aboutMainCircleBlurGreen = "AboutMain-module--aboutMainCircleBlurGreen--cd4a2";
export var aboutMainCircleRightBlue = "AboutMain-module--aboutMainCircleRightBlue--192e5";
export var aboutMainContainer = "AboutMain-module--aboutMainContainer--6fa50";
export var aboutMainContent = "AboutMain-module--aboutMainContent--796f5";
export var aboutUsAws = "AboutMain-module--aboutUsAws--927c8";
export var aboutUsDescription = "AboutMain-module--aboutUsDescription--33944";
export var aboutUsYears = "AboutMain-module--aboutUsYears--b3ca8";
export var backgroundCircles = "AboutMain-module--backgroundCircles--21bc2";
export var heroBackgroundCircleLeft = "AboutMain-module--heroBackgroundCircleLeft--e8293";
export var heroWhiteBlurCirce = "AboutMain-module--heroWhiteBlurCirce--0f122";