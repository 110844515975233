// extracted by mini-css-extract-plugin
export var PartnerMobileCarousel = "OurCustomersMainSlider-module--PartnerMobileCarousel--ddcb1";
export var PartnersCarouselDesc = "OurCustomersMainSlider-module--PartnersCarouselDesc--3769f";
export var carousel = "OurCustomersMainSlider-module--carousel--459d6";
export var carouselDiv = "OurCustomersMainSlider-module--carouselDiv--ddd4d";
export var carouselImage = "OurCustomersMainSlider-module--carouselImage--baea4";
export var carouselImageDiv = "OurCustomersMainSlider-module--carouselImageDiv--121f0";
export var carouselImg = "OurCustomersMainSlider-module--carouselImg--574f8";
export var carouselImgDiv = "OurCustomersMainSlider-module--carouselImgDiv--cbf61";
export var carouselMainDiv = "OurCustomersMainSlider-module--carouselMainDiv--5a07e";
export var carousel__container = "OurCustomersMainSlider-module--carousel__container--bd39d";
export var carousel__slider = "OurCustomersMainSlider-module--carousel__slider--39765";
export var carousel__sliderTrayWrapHorizontal = "OurCustomersMainSlider-module--carousel__slider-tray-wrap--horizontal--8ffbb";
export var carousel__sliderTrayWrapper = "OurCustomersMainSlider-module--carousel__slider-tray-wrapper--ccb2e";
export var caseStudiesDescription = "OurCustomersMainSlider-module--caseStudiesDescription--73abb";
export var contactButton = "OurCustomersMainSlider-module--contactButton--79ee5";
export var evenSlides = "OurCustomersMainSlider-module--evenSlides--cac2a";
export var partnersMainSlider = "OurCustomersMainSlider-module--partnersMainSlider--da4ed";
export var subDiv = "OurCustomersMainSlider-module--subDiv--85e07";