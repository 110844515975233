// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "ServicesMain-module--aboutMainCircleBlurBlue--83e56";
export var aboutMainCircleBlurGreen = "ServicesMain-module--aboutMainCircleBlurGreen--ad996";
export var articleServiceDescription = "ServicesMain-module--articleServiceDescription--5aa8a";
export var articleServiceReadMoreBtn = "ServicesMain-module--articleServiceReadMoreBtn--92f92";
export var articleServiceTitleAndNumber = "ServicesMain-module--articleServiceTitleAndNumber--4edff";
export var articlesButton = "ServicesMain-module--articlesButton--a379b";
export var articlesServicesNumbersPositionContainer = "ServicesMain-module--articlesServicesNumbersPositionContainer--9e11d";
export var articlesServicesRightSide = "ServicesMain-module--articlesServicesRightSide--45ada";
export var backgroundCircles = "ServicesMain-module--backgroundCircles--62f05";
export var caseStudiesTitleCircle = "ServicesMain-module--caseStudiesTitleCircle--99b39";
export var heroBackgroundCircleLeft = "ServicesMain-module--heroBackgroundCircleLeft--f640a";
export var heroWhiteBlurCirce = "ServicesMain-module--heroWhiteBlurCirce--691e5";
export var innerServiceContainer = "ServicesMain-module--innerServiceContainer--b329a";
export var moveDown = "ServicesMain-module--moveDown--c84dc";
export var moveDown3 = "ServicesMain-module--moveDown3--3a335";
export var moveDown4 = "ServicesMain-module--moveDown4--614d1";
export var serviceContinerDiv = "ServicesMain-module--serviceContinerDiv--b7587";
export var servicesHeader2 = "ServicesMain-module--servicesHeader2--616b1";
export var servicesHeader3 = "ServicesMain-module--servicesHeader3--08320";
export var servicesHeader4 = "ServicesMain-module--servicesHeader4--05887";
export var servicesLeftAndRightSideWrapper = "ServicesMain-module--servicesLeftAndRightSideWrapper--c9ca6";
export var servicesMainContainer = "ServicesMain-module--servicesMainContainer--6a87d";
export var titleLeftSide = "ServicesMain-module--titleLeftSide--d4ef1";