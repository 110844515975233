// extracted by mini-css-extract-plugin
export var PartnerMobileCarousel = "OurCustomersMobileSlider-module--PartnerMobileCarousel--ea175";
export var PartnersCarouselDesc = "OurCustomersMobileSlider-module--PartnersCarouselDesc--1acbd";
export var blogArrowContainer = "OurCustomersMobileSlider-module--blogArrowContainer--2a5de";
export var carousel = "OurCustomersMobileSlider-module--carousel--9044d";
export var carouselDiv = "OurCustomersMobileSlider-module--carouselDiv--fc31d";
export var carouselImage = "OurCustomersMobileSlider-module--carouselImage--46294";
export var carouselImageDiv = "OurCustomersMobileSlider-module--carouselImageDiv--63f80";
export var carouselImg = "OurCustomersMobileSlider-module--carouselImg--fe9cf";
export var carouselImgDiv = "OurCustomersMobileSlider-module--carouselImgDiv--5d80d";
export var carouselInnerDiv = "OurCustomersMobileSlider-module--carouselInnerDiv--844a3";
export var carouselMainDiv = "OurCustomersMobileSlider-module--carouselMainDiv--760a1";
export var carouselSubDiv = "OurCustomersMobileSlider-module--carouselSubDiv--32353";
export var carousel__container = "OurCustomersMobileSlider-module--carousel__container--6eb3d";
export var carousel__slider = "OurCustomersMobileSlider-module--carousel__slider--e8aff";
export var carousel__sliderTrayWrapHorizontal = "OurCustomersMobileSlider-module--carousel__slider-tray-wrap--horizontal--a6e14";
export var carousel__sliderTrayWrapper = "OurCustomersMobileSlider-module--carousel__slider-tray-wrapper--72dd6";
export var caseStudiesDescription = "OurCustomersMobileSlider-module--caseStudiesDescription--be483";
export var contactButton = "OurCustomersMobileSlider-module--contactButton--5fa45";
export var evenSlides = "OurCustomersMobileSlider-module--evenSlides--51593";
export var partnersMainSlider = "OurCustomersMobileSlider-module--partnersMainSlider--da856";
export var subDiv = "OurCustomersMobileSlider-module--subDiv--53c0c";