// extracted by mini-css-extract-plugin
export var ContentBlock = "TestimonialMain-module--ContentBlock--7d158";
export var ImageBox = "TestimonialMain-module--ImageBox--0919f";
export var ImageBoxForth = "TestimonialMain-module--ImageBoxForth--cc298";
export var ImageBoxSecond = "TestimonialMain-module--ImageBoxSecond--707c7";
export var TextBox = "TestimonialMain-module--TextBox--2ad03";
export var TextBoxLast = "TestimonialMain-module--TextBoxLast--92f11";
export var application = "TestimonialMain-module--application--d669a";
export var applicationHeading = "TestimonialMain-module--applicationHeading--39efc";
export var applicationMap = "TestimonialMain-module--applicationMap--9c330";
export var approach = "TestimonialMain-module--approach--179c0";
export var backgroundCircles = "TestimonialMain-module--backgroundCircles--1f223";
export var banner = "TestimonialMain-module--banner--5dca3";
export var benefits = "TestimonialMain-module--benefits--cff72";
export var bottomButton = "TestimonialMain-module--bottomButton--5000b";
export var careerBtnApplyNow = "TestimonialMain-module--careerBtnApplyNow--cf93e";
export var careers = "TestimonialMain-module--careers--1f81d";
export var caseChallengeImage = "TestimonialMain-module--caseChallengeImage--005d9";
export var caseTestimonialImage = "TestimonialMain-module--caseTestimonialImage--064a2";
export var contact = "TestimonialMain-module--contact--b31df";
export var contactButton = "TestimonialMain-module--contactButton--240e8";
export var contactOutlineCircleBenfits = "TestimonialMain-module--contactOutlineCircleBenfits--e7f49";
export var coreValue = "TestimonialMain-module--coreValue--ddf2b";
export var coreValueColumbLeft = "TestimonialMain-module--coreValueColumbLeft--6d7e9";
export var coreValueColumbRight = "TestimonialMain-module--coreValueColumbRight--95cf9";
export var coreValueItem = "TestimonialMain-module--coreValueItem--fb5e5";
export var coreValueItemBox = "TestimonialMain-module--coreValueItemBox--db07e";
export var flowContainer = "TestimonialMain-module--flowContainer--455f5";
export var flowItem = "TestimonialMain-module--flowItem--80248";
export var flowItemDay = "TestimonialMain-module--flowItemDay--2899c";
export var flowItemDayMobile = "TestimonialMain-module--flowItemDayMobile--bbcf0";
export var flowItemDayMobileContainer = "TestimonialMain-module--flowItemDayMobileContainer--59e4d";
export var flowItemMobile = "TestimonialMain-module--flowItemMobile--00379";
export var flowLeftContainer = "TestimonialMain-module--flowLeftContainer--bfa78";
export var flowRightContainer = "TestimonialMain-module--flowRightContainer--1d3eb";
export var flowRightContainerMobile = "TestimonialMain-module--flowRightContainerMobile--96d13";
export var flowStop = "TestimonialMain-module--flowStop--dec4a";
export var followUs = "TestimonialMain-module--followUs--bc4fd";
export var gdnPromotePic = "TestimonialMain-module--gdnPromotePic--ac6b2";
export var heading = "TestimonialMain-module--heading--cb3b0";
export var heroBackgroundCircleLeft = "TestimonialMain-module--heroBackgroundCircleLeft--65bea";
export var heroBackgroundInnerCircleGreen = "TestimonialMain-module--heroBackgroundInnerCircleGreen--0ac50";
export var heroBackgroundInnerCircleLeft = "TestimonialMain-module--heroBackgroundInnerCircleLeft--82a89";
export var heroBackgroundInnerCircleLeftBenfits = "TestimonialMain-module--heroBackgroundInnerCircleLeftBenfits--dc254";
export var heroBannerMainContainer = "TestimonialMain-module--heroBannerMainContainer--9d278";
export var heroWhiteBlurCirce = "TestimonialMain-module--heroWhiteBlurCirce--ab356";
export var igImageGroup1 = "TestimonialMain-module--igImageGroup1--cfbe6";
export var igImageGroup1Item = "TestimonialMain-module--igImageGroup1Item--f8b1f";
export var igImageGroup2 = "TestimonialMain-module--igImageGroup2--edc66";
export var igImageGroup2Btm = "TestimonialMain-module--igImageGroup2Btm--262e6";
export var igImageGroup2BtmItem = "TestimonialMain-module--igImageGroup2BtmItem--60e27";
export var igImageGroup2Item = "TestimonialMain-module--igImageGroup2Item--5190f";
export var inNumberColumbLeft = "TestimonialMain-module--inNumberColumbLeft--ba1b3";
export var inNumberColumbRight = "TestimonialMain-module--inNumberColumbRight--ee52e";
export var inNumbersImage = "TestimonialMain-module--inNumbersImage--dc038";
export var inNumbersItem = "TestimonialMain-module--inNumbersItem--c1788";
export var innumbers = "TestimonialMain-module--innumbers--6aee0";
export var insights = "TestimonialMain-module--insights--30734";
export var instaContainer = "TestimonialMain-module--instaContainer--14509";
export var instaLeftBox = "TestimonialMain-module--instaLeftBox--31d8d";
export var instaRightBox = "TestimonialMain-module--instaRightBox--f4a15";
export var introDescriptionButtonSection = "TestimonialMain-module--introDescriptionButtonSection--c4556";
export var introImageSection = "TestimonialMain-module--introImageSection--10b7b";
export var introSectionCircleItem = "TestimonialMain-module--introSectionCircleItem--f25b9";
export var introSectionContainer = "TestimonialMain-module--introSectionContainer--79831";
export var introSectionText = "TestimonialMain-module--introSectionText--67b7d";
export var keyTopicsImage = "TestimonialMain-module--keyTopicsImage--18b30";
export var knowUs = "TestimonialMain-module--knowUs--4f60d";
export var knowUsImage = "TestimonialMain-module--knowUsImage--ad918";
export var knowUsText = "TestimonialMain-module--knowUsText--76b67";
export var pillars = "TestimonialMain-module--pillars--19133";
export var reportBtn = "TestimonialMain-module--reportBtn--8c315";
export var reportBtnGerman = "TestimonialMain-module--reportBtnGerman--df018";
export var singleCaseTestimonial = "TestimonialMain-module--singleCaseTestimonial--df5a2";
export var singleCaseTestimonialInner = "TestimonialMain-module--singleCaseTestimonialInner--ef42a";
export var sliderItem = "TestimonialMain-module--sliderItem--b274a";
export var testimonialBody = "TestimonialMain-module--testimonialBody--cce35";
export var testimonialButtonsContainer = "TestimonialMain-module--testimonialButtonsContainer--dfd49";
export var testimonialContent = "TestimonialMain-module--testimonialContent--11652";
export var testimonialMainContainer = "TestimonialMain-module--testimonialMainContainer--c96fd";
export var testimonialName = "TestimonialMain-module--testimonialName--cf26f";
export var testimonialSliderContainer = "TestimonialMain-module--testimonialSliderContainer--0eafe";
export var torchWrapper = "TestimonialMain-module--torchWrapper--2d7bf";
export var verticalLine = "TestimonialMain-module--verticalLine--d5489";
export var verticalLineMobile = "TestimonialMain-module--verticalLineMobile--7ea00";
export var yourBenefits = "TestimonialMain-module--yourBenefits--f86be";
export var yourBenefitsIcon = "TestimonialMain-module--yourBenefitsIcon--ef542";
export var yourBenefitsInner = "TestimonialMain-module--yourBenefitsInner--e8d24";
export var yourBenefitsItem = "TestimonialMain-module--yourBenefitsItem--ba7ce";